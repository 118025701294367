import React, { useState,useEffect } from "react";
import { motion as m } from "framer-motion";
import Navcomponent from "./navbar";
import { Helmet } from "react-helmet";
import bsdicipline from '../Documents/BS-Disciplines.pdf'
import academicsystem from '../Documents/Academic-System-Affiliated-Colleges-II.pdf'
import Footer from "./footer";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useParams } from "react-router-dom";
gsap.registerPlugin(ScrollTrigger);

const Blogsdetail = () => {
  const [ids, setIds] = useState();
  const params = useParams();
  const id = params.id;

  useEffect(() => {
    console.log("pathcheck", id);
    setIds(id);
  });

  const Testblogs = () => {
    if (ids === "1") {
      return (
        <>
          <div className="container mt-40 mb-30">
            <div className="row">
                <div className="mainText">
                  <h1>Announcement of Admission Schedule for Affiliated Colleges. BS Admissions Open wef.</h1>
                  <p className="blogdates">
                    June 24, 2024 | <a href={bsdicipline} target="_blank" rel="noreferrer">Click Here </a>
                  </p>
                </div>
           
              <div className="col-md-12">
                <div className="blog1">
                  <img
                    src={process.env.PUBLIC_URL + "/assets/images/news1.jpeg"}
                    alt="blogs"
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
    if (ids === "2") {
      return (
        <>
          <div className="container mt-40 mb-30">
            <div className="row">
                <div className="mainText">
                  <h1>Academic System for Affiliated Colleges</h1>
                  <p className="blogdates">
                  03 Septemper 2024 | <a href={academicsystem} target="_blank" rel="noreferrer">Click Here </a>
                  </p>
                </div>
           
              <div className="col-md-12">
                <div className="blog1">
                  <img
                    src={process.env.PUBLIC_URL + "/assets/images/academicsystemaffiliation.png"}
                    alt="blogs"
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
  };
  return (
    <m.div
      initial={{ scaleY: 0 }}
      animate={{ scaleY: 1 }}
      exit={{ scaleY: 0 }}
      transition={{ duration: 0.5 }}
    >
      <>
        <Helmet>
          <title>Blogs |</title>
          <meta name="description" content="Get info about ConstantinePR" />
          <meta
            name="keywords"
            content="digital marketing agency, digital marketing agency pakistan, digital advertising agency, digital advertising agencies near me, branding agencies, branding and marketing agency, digital strategy, web development, app development, media production, event management, rebranding, brand management, social media marketing, corporate branding, corporate marketing strategy, print media, digital media        "
          />
        </Helmet>

        <div className="smooth-wrapper" id="smooth-wrapper">
          <div id="smooth-content">
            <section id="blogDetails">
              {/* <Navcomponent /> */}
              <Testblogs />
            </section>
            <Footer />
          </div>
        </div>
      </>
    </m.div>
  );
};

export default Blogsdetail;
