import React, { useEffect } from "react";
import { motion as m } from "framer-motion";
import { Helmet } from "react-helmet";
import AOS from "aos";
import "aos/dist/aos.css";

const Rector = () => {
    useEffect(() => {
        AOS.init();
    }, []);
    return (
        <m.div
            initial={{ scaleY: 0 }}
            animate={{ scaleY: 1 }}
            exit={{ scaleY: 0 }}
            transition={{ duration: 0.5 }}
        >
            <>
                <Helmet>
                    <title>Rector |</title>
                </Helmet>
                <section id="administration">
                    <div className="container">
                        <div className="row">
                            <div className="admin-heading flex-center">
                                <h1 className="admin-text">RECT
                                    <img className="o" src={'./assets/images/o.png'} alt="O" />R</h1>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="main-administration" className="lightgeenbg">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3 middle border-rightgreen">
                                <div className="admin-img">
                                    <img className="img-admin" src={'./assets/images/dg.jpeg'} alt="DG" />
                                    <div className="admin_name">
                                        <h5>MAJ GEN QAISAR SULEMAN</h5>
                                    </div>
                                </div>

                            </div>
                            <div className="col-md-9">
                                <div className="about-admin">
                                    <div className="admin-pill font-tomato" data-aos="fade-up">
                                        Message
                                    </div>
                                    <div className="about-adminpara">
                                        <p className="font-tomato" data-aos="fade-right">National University of Pakistan is
                                            embarking upon a journey to achieve
                                            greater excellence. It has been some
                                            years since the idea of the University was
                                            conceived, and still the process of
                                            offering new valuable initiatives and
                                            improvements remains a focus of
                                            everyone at our university’s community.
                                            <br /><br />
                                            We have taken a huge commitment and
                                            responsibility towards society, and it is
                                            through being an exemplary and leading
                                            institution that we can best serve it.
                                            <br /><br />
                                            <i><b>"Strategic Planning is allowing us to make a reality of our vision."</b></i>
                                        </p>
                                        <p className="font-tomato" data-aos="fade-right">Strategic Plan presented here is the product of a great deal of effort by our team
                                            and we are proud of what it has contributed. Nevertheless, planning is not a single time
                                            exercise; it is an ongoing process that requires continual input from both academic and
                                            administrative wings; and the components of the plan may be revisited and updated as
                                            found necessary.
                                            <br /><br />
                                            <i><b>"I deeply appreciate the involvement and contribution of my team."</b></i>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        </m.div>
    )


}

export default Rector